import './GoBack.css'
import { Link } from 'react-router-dom'

export const GoBack = () => {
    return (
        <div className='go-back'>
            <Link to={'/'}>
                <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="20" height="20" fill='white'><path d="M.88,14.09,4.75,18a1,1,0,0,0,1.42,0h0a1,1,0,0,0,0-1.42L2.61,13H23a1,1,0,0,0,1-1h0a1,1,0,0,0-1-1H2.55L6.17,7.38A1,1,0,0,0,6.17,6h0A1,1,0,0,0,4.75,6L.88,9.85A3,3,0,0,0,.88,14.09Z" /></svg>
                Revenir en arrière
            </Link>
        </div>
    )
}

export default GoBack
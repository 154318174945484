// import React, { Component } from 'react'

// import { useRouteError } from "react-router-dom";
import './ErrorPage.css'

export const ErrorPage = () => {
    // const error = useRouteError();

    return (
        <main className='center'>
            <div className='wrapper'>
                <h1>Oups !</h1>
                <h2>Cette page est introuvable.</h2>
            </div>
        </main>
    )
}

export default ErrorPage

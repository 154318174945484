import Markdown from "react-markdown"
import { LEGAL_DETAILS_MD } from "../../assets/legal/legalDetails"
import GoBack from "../../components/GoBack/GoBack"

export const LegalDetailsPage = () => {
    return (
        <main className="center">
            <div className="wrapper">
                <GoBack />
                <h1>Mentions légales</h1>
                <Markdown>{LEGAL_DETAILS_MD}</Markdown>
            </div>
        </main>
    )
}

export default LegalDetailsPage
import React from 'react';
import './App.css';

import HomePage from './pages/HomePage/HomePage';
import FooterPage from './pages/FooterPage/FooterPage';

function App() {
    return (
        <div className="App">
            <header className="App-header">

            </header>
            <HomePage />
            <FooterPage />
        </div>
    );
}

export default App;
import './HomePage.css'

export const HomePage = () => {
    return (
        <main id='home' className='center'>
            <h1>Numeraven</h1>
        </main>
    )
}

export default HomePage

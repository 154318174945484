// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#home {
    margin-top: -75px;
    min-height: 100vh;
}

.app-logo {
    width: 190px;
}

.app-store-logos {
    margin-top: 40px;
}

.app-store-logos a:first-child {
    padding-right: 20px;
}

.app-store-logo {
    width: 140px;
}

#home h1 {
    margin-top: 25px;
}

#home h4 {
    text-align: center;
    font-weight: 400;
}

@media screen and (min-width: 600px) {

    .app-logo {
        width: 250px;
    }

    .app-store-logo {
        width: 190px;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/HomePage/HomePage.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;;IAEI;QACI,YAAY;IAChB;;IAEA;QACI,YAAY;IAChB;AACJ","sourcesContent":["#home {\n    margin-top: -75px;\n    min-height: 100vh;\n}\n\n.app-logo {\n    width: 190px;\n}\n\n.app-store-logos {\n    margin-top: 40px;\n}\n\n.app-store-logos a:first-child {\n    padding-right: 20px;\n}\n\n.app-store-logo {\n    width: 140px;\n}\n\n#home h1 {\n    margin-top: 25px;\n}\n\n#home h4 {\n    text-align: center;\n    font-weight: 400;\n}\n\n@media screen and (min-width: 600px) {\n\n    .app-logo {\n        width: 250px;\n    }\n\n    .app-store-logo {\n        width: 190px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import './FooterPage.css'
import { Link } from 'react-router-dom'

export const FooterPage = () => {
    return (
        <footer>
            <nav className='bottom-nav'>
                <ul>
                    {/* <li><Link to={'privacy-policy'}>Politique de confidentialité</Link></li> */}
                    <li><Link to={'legal-details'}>Mentions légales</Link></li>
                </ul>
            </nav>
        </footer>
    )
}

export default FooterPage

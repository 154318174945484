// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bottom-nav ul {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    justify-content: center;
    margin: 0;
    padding: 0;
}

.bottom-nav li {
    margin: 0 10px 0 10px;
}

.bottom-nav li a {
    text-decoration: none;
}

.bottom-nav li a:hover {
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/pages/FooterPage/FooterPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,uBAAuB;IACvB,SAAS;IACT,UAAU;AACd;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".bottom-nav ul {\n    display: flex;\n    flex-direction: row;\n    list-style-type: none;\n    justify-content: center;\n    margin: 0;\n    padding: 0;\n}\n\n.bottom-nav li {\n    margin: 0 10px 0 10px;\n}\n\n.bottom-nav li a {\n    text-decoration: none;\n}\n\n.bottom-nav li a:hover {\n    text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
